.button-click {
    display: flex;
    width: 10em;
    margin: 10px auto;

}

.edit-gift-button {
    text-align: center;
    padding: 15px;
    background-color: #4096ff;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}