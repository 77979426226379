.advertise {
    display: grid;
    padding: 5px;
    grid-template-columns: repeat(2, 1fr);
}

.ad-alliance-fix {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: -webkit-flex;
    margin-top: 10px;
    display: flex;
    width: 95%;

}

.ad-alliance-img {
    width: 3.3em;
    height: 3.3em;
    border-radius: 5px;
    position: relative;
}
.ad-alliance-img>.ad-img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}


.ad-alliance-context {
    border-radius: 10px;
    color: #000;
    width: 6em;
    padding: 8px;
}

.tb {
    font-size: 11px;
    text-align: center;
    height: 1.6em;
    color: #ff5000;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.jd{
    font-size: 11px;
    text-align: center;
    color: #e1251b;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
}

h4+.pText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 9px;
    -webkit-line-clamp: 2;
}



