.ad-perfer{
  overflow:auto;
  text-overflow: ellipsis;
  height: 83vh;
}
.preferential {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 15px;
}

.ad-preferential-fix {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 1px 1px rgb(0,0, 0, 0.2);
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  width: 90%;
  margin-top: 18px;
}

.ad-preferential-img {
  width: 4.3em;
  height: 4.3em;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.ad-preferential-img>.ad-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ad-preferential-context {
  border-radius: 10px;
  width: 6em;
  color: #000;
}

.tb {
  text-align: center;
  height: 1.2em;
  color: #ff5000;
  font-size: 10px;
  
}
.jd{
  text-align: center;
  height: 1.2em;
  color: #e1251b;
  font-size: 10px;
}

h4+p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 9px;
}

.ad-preferential-img > .font {
  position: absolute;
  z-index: 2;
  left: 2.2em;
  height: 0.8em;
  color: #fc4103;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2em;
}
.p-originalPrice{
  font-size: 9px;
  color: rgb(51,154,129);
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.p-discount {
  font-size: 9px;
  color:#d47982;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.p-price {
  font-size: 9px;
  color: #fc4103;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.search-input{
  width: 95%;
  height: 3em;
  margin: 0 auto;
}
.tabs{
  width: 100%;
  height: 2em;
  margin: 0 auto;
}
.hint{
  width: 15em;
  margin: 20px auto;
  font-size: 20px;
  color: rgb(212, 209, 211);
}
.ad-preferential-context > h4{
  font-size: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.5em;
}