.lucky-wheel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 20px;
    max-height: 47vh;
    min-height: 15em;
    overflow: auto;
}

.lucky-wheel-item {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #eee;
    color: #333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected {
    background-color: #ff5000;
    color: #eee;
}

.lucky-click {
    display: flex;
    width: 13em;
    margin: 10px auto;
}

.lucky-wheel-edit {
    width: 60px;
    text-align: center;
    padding: 15px;
    border: 1px solid #4096ff !important;
    color: #4096ff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.lucky-wheel-button {
    width: 60px;
    text-align: center;
    padding: 10px;
    background-color: #4096ff;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}


.span-lucky {
    color: #ff5000;
}




.lucky-wheel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 20px;
    min-height: 15em;
    overflow: auto;
    max-height: 45.5vh;
}

.lucky-wheel-item {
    display: flex;
    height: 5em;
    width: 5em;
    border-radius: 50%;
    background-color: #eee;
    color: #333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease-in-out;
}
.lucky-img{
    width: 5em;
    height: 5em;
    border-radius: 10px;
}
.lucky-img > img {
    width: 100%;
    height: 100%;
}
.lucky-context{
    margin-left: 0.5em;
    height: 4.8em;
    width: 13em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

}
.selected {
    background-color: #ff5000;
    color: #ffffff;
}

.lucky-click {
    display: flex;
    width: 13em;
    margin: 10px auto;
}

.lucky-wheel-edit {
    width: 60px;
    text-align: center;
    padding: 15px;
    border: 1px solid #4096ff !important;
    color: #4096ff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.lucky-wheel-button {
    width: 60px;
    text-align: center;
    padding: 10px;
    background-color: #4096ff;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.lucky-vale {
    font-size: 18px;
    width: 79%;
    overflow:hidden;
    text-overflow:ellipsis; 
    white-space:nowrap; 
}

.span-lucky {
    color: #5597f3;
}
.quey-value > a{
    font-size: 13px;
    color: #d4d1d3;
}