* {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}
a{
    color: #8f8f8f;
}
.base {
    display: grid;
    height: 100%;
}

.nav-head {
    background-color: #f6f7fa;
    display: grid;
    grid-template-columns: 60px auto;
}

.head-log {
    height: 4em;
    display: flex;

}

.logImage {
    width: 4em;
    height: 4em;
}




.linkD {
    padding: 10px;
    display: block;
    color: #333;
}
.navigation-head{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.left-navigation {
    text-align: center;
    padding: 5px;
}

.left-navigation:hover {
    text-align: center;
    background-color: #e8e8fd;
}

 .navigation-click{
    background-color: #e8e8fd;
}

.nav-context {
    display: flow-root;
    position: relative;
    min-height: 100vh;
}
.context-outer{
    max-height: 75%;
}
.context-operate {
    height: 90vh;
    max-width: 75em;
    min-height: 47em;
    margin: 0 auto;
    background-color: #fbfbfb;
}

.font {
    height: 5%;
    width: 80%;
    margin: 0 auto;
    bottom: 0;
    text-align: center;
    font-size: 14px;
}
.ad{
    max-width: 44em;
    margin: 0 auto;
}